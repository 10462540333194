import React, { useState } from "react"
import { Spinner, Button } from "react-bootstrap"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../../firebase"
import { Form } from "react-bootstrap"

interface Props {
  accountId: string
}
const CountOldIGAssets = ({ accountId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [noOfAssets, setNoOfAssets] = useState<string>("")
  const [months, setMonths] = useState<number>(24)

  const adminNoOfOldIGAssets = async () => {
    const adminNoOfOldIGAssets = httpsCallable(
      functions,
      "adminNoOfOldIGAssets"
    )

    try {
      setIsLoading(true)
      const res = await adminNoOfOldIGAssets({ accountId, months })
      console.log(res)
      const assets = res.data as string
      setNoOfAssets(assets)
      setIsLoading(false)
    } catch (err) {
      console.error(err)
      setIsLoading(false)
    }
  }

  return isLoading ? (
    <Spinner animation="border" size="sm" />
  ) : (
    <Form.Group>
      <Form.Control
        type="range"
        name="automaticDeleteAfter"
        min={1}
        max={24}
        value={months}
        onChange={e => setMonths(Number(e.target.value))}
        className="form-range"
      />{" "}
      <span>{months}</span>
      {noOfAssets && <span>Old assets: {noOfAssets}</span>}
      <Button onClick={() => adminNoOfOldIGAssets()}>
        Count old IG assets
      </Button>
    </Form.Group>
  )
}

export default CountOldIGAssets
